// Generated by Framer (1cc6588)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, Link, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Material } from "https://framerusercontent.com/modules/6Ldpz1V0DkD45gXvi67I/PCgBX5d6MdQT7E7nhdXn/Material.js";
const MaterialFonts = getFonts(Material);

const enabledGestures = {fzd_rCwst: {hover: true}};

const cycleOrder = ["fzd_rCwst"];

const serializationHash = "framer-I7Uf7"

const variantClassNames = {fzd_rCwst: "framer-v-1we96ta"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({color, height, id, link, name1, width, ...props}) => { return {...props, BvXPbvX4N: color ?? props.BvXPbvX4N ?? "var(--token-d3c2bf5e-776e-48a3-853c-b56b8e265c21, rgb(255, 255, 255)) /* {\"name\":\"White\"} */", JB3f2tPPt: link ?? props.JB3f2tPPt, pW0rMDIeO: name1 ?? props.pW0rMDIeO ?? "facebook"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;name1?: string;link?: string;color?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, pW0rMDIeO, JB3f2tPPt, BvXPbvX4N, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "fzd_rCwst", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-I7Uf7", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link href={JB3f2tPPt} openInNewTab><motion.a {...restProps} className={`${cx("framer-1we96ta", className)} framer-2n92np`} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"fzd_rCwst"} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "rgb(143, 143, 143)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "rgba(0, 0, 0, 0)", borderBottomLeftRadius: 60, borderBottomRightRadius: 60, borderTopLeftRadius: 60, borderTopRightRadius: 60, ...style}} variants={{"fzd_rCwst-hover": {"--border-color": "var(--token-418422ae-c2d7-406d-b663-74c4b3eabc44, rgb(180, 233, 37)) /* {\"name\":\"Accent\"} */", backgroundColor: "var(--token-418422ae-c2d7-406d-b663-74c4b3eabc44, rgb(180, 233, 37))"}}} {...addPropertyOverrides({"fzd_rCwst-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><motion.div className={"framer-1kxond4-container"} layoutDependency={layoutDependency} layoutId={"TKv06wXGB-container"}><Material color={BvXPbvX4N} height={"100%"} iconSearch={pW0rMDIeO} iconSelection={"Home"} iconStyle15={"Filled"} iconStyle2={"Filled"} iconStyle7={"Filled"} id={"TKv06wXGB"} layoutId={"TKv06wXGB"} mirrored={false} selectByList={false} style={{height: "100%", width: "100%"}} width={"100%"} {...addPropertyOverrides({"fzd_rCwst-hover": {color: "var(--token-bec8f50d-5c09-4d71-a229-de4580e07deb, rgb(9, 9, 9)) /* {\"name\":\"Dark\"} */"}}, baseVariant, gestureVariant)}/></motion.div></motion.a></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-I7Uf7 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-I7Uf7 .framer-2n92np { display: block; }", ".framer-I7Uf7 .framer-1we96ta { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 38px; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; text-decoration: none; width: 38px; will-change: var(--framer-will-change-override, transform); }", ".framer-I7Uf7 .framer-1kxond4-container { flex: none; height: 18px; position: relative; width: 18px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-I7Uf7 .framer-1we96ta { gap: 0px; } .framer-I7Uf7 .framer-1we96ta > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-I7Uf7 .framer-1we96ta > :first-child { margin-left: 0px; } .framer-I7Uf7 .framer-1we96ta > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 38
 * @framerIntrinsicWidth 38
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"hLe329Jkh":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"pW0rMDIeO":"name1","JB3f2tPPt":"link","BvXPbvX4N":"color"}
 * @framerImmutableVariables true
 */
const FramerT6lyCm4_d: React.ComponentType<Props> = withCSS(Component, css, "framer-I7Uf7") as typeof Component;
export default FramerT6lyCm4_d;

FramerT6lyCm4_d.displayName = "Social Media Icon";

FramerT6lyCm4_d.defaultProps = {height: 38, width: 38};

addPropertyControls(FramerT6lyCm4_d, {pW0rMDIeO: {defaultValue: "facebook", placeholder: "Menu, Wifi, Box…", title: "Name", type: ControlType.String}, JB3f2tPPt: {title: "Link", type: ControlType.Link}, BvXPbvX4N: {defaultValue: "var(--token-d3c2bf5e-776e-48a3-853c-b56b8e265c21, rgb(255, 255, 255)) /* {\"name\":\"White\"} */", title: "Color", type: ControlType.Color}} as any)

addFonts(FramerT6lyCm4_d, [...MaterialFonts])